import { useTranslations } from 'next-intl';
import cn from 'classnames';
import { Industry } from '@ee-monorepo/shared/utilities/types';
import s from './industry-category.module.scss';
import { Card } from '@ee-monorepo/shared/ui/card';
import { IconIndustry } from '../../lazy/icon-industry';

export interface IndustryCardProps {
  classes?: string;
  iconColor?: string;
  fontClass?: string;
  isMobile?: boolean;
  industry: { name: string; industry: string };
  active?: boolean;
  onClick: (industry: Industry) => void;
  onMouseOver?: (industry: Industry) => void;
}

export const IndustryCard = ({
  industry,
  onClick,
  active,
  classes,
  iconColor,
  fontClass,
  isMobile,
}: IndustryCardProps) => {
  const t = useTranslations();

  return (
    <Card
      data-testid="industry-item"
      onClick={() => onClick(industry.industry as Industry)}
      className={cn(classes, s.cardLayout, { [s.cardLayoutActive]: active })}
    >
      <div className="header flex flex-col items-center">
        <a
          href={`online-stores?industry=${industry.industry}`}
          onClick={(event) => event.preventDefault()}
          className="text-decoration-none text-center"
        >
          <div className="logoWrapper">
            <IconIndustry
              iconColor={iconColor}
              data-testid={`industry-${industry.industry}`}
              industry={industry?.industry as Industry}
              size="sm"
              className="w-[48px] mb-2"
            />
          </div>
          <div>
            <div
              className={cn(
                fontClass ?? 'text-SnapGrey500 bodyFont',
                'text-center'
              )}
            >
              {isMobile
                ? t(`shared.industries.${industry.industry}.label`).split(
                    ' '
                  )[0]
                : t(`shared.industries.${industry.industry}.label`)}
            </div>
          </div>
        </a>
      </div>
    </Card>
  );
};
