import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from '@ee-monorepo/shared/utilities/hooks';
import { Merchant } from '@ee-monorepo/shared/utilities/types';
import {
  selectKeyword,
  selectKeywordSuggestions,
  selectViewMode,
  selectSuggestionsLoading,
} from '@ee-monorepo/store-locator/shared/data-access';
import s from './merchant-keyword-filter.module.scss';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import {
  useStoreLocatorRouterActions,
  useStoreLocatorActions,
} from '@ee-monorepo/store-locator/shared/data-access';
import { useRouter } from 'next/router';
import { trackStoreLocatorSearchEvent } from '@ee-monorepo/shared/utilities/functions';
import { TextField } from '@ee-monorepo/shared/ui/text-field';
import { useTranslations } from 'next-intl';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchBoxSchema from '../../schema/schema-searchbox';

interface MerchantKeywordFilterProps {
  onClick?: () => void;
  collapsed?: boolean;
  placeholder?: string;
}

export function MerchantKeywordFilter(props: MerchantKeywordFilterProps) {
  const { suggestByKeyword, setKeyword } = useStoreLocatorActions();
  const { pushSearch } = useStoreLocatorRouterActions();
  const {
    query: { zipCode, industry, latitude, longitude },
    pathname,
  } = useRouter();
  const t = useTranslations();
  const viewMode = useSelector(selectViewMode);
  const keywordSuggestions = useSelector(selectKeywordSuggestions);
  const suggestionsLoading = useSelector(selectSuggestionsLoading);
  const keyword = useSelector(selectKeyword);
  const inputValueDebounced = useDebounce(keyword, 800);
  const inputRef = useRef(null);
  const { tracking } = useTrackingContext();
  const trackKeyword = useDebounce(keyword, 1500);
  const [isSearchClicked, setSearchclicked] = useState(null);

  useEffect(() => {
    const suggest = async (keyWord: string) => {
      suggestByKeyword(
        {
          keyWord,
          zipCode: zipCode as string,
          industry: industry as string,
          userCoordinates: {
            latitude: parseFloat(latitude as string),
            longitude: parseFloat(longitude as string),
          },
        },
        viewMode
      );
    };
    if (inputValueDebounced) {
      suggest(inputValueDebounced);
    }
  }, [
    inputValueDebounced,
    suggestByKeyword,
    viewMode,
    zipCode,
    industry,
    latitude,
    longitude,
  ]);

  useEffect(() => {
    if (trackKeyword && keyword && trackKeyword === keyword) {
      tracking?.logSearchEvent({
        search_term: trackKeyword,
        search_type: 'keyword',
      });
    }
  }, [trackKeyword, industry, tracking, pathname, keyword]);

  return (
    <div className={s.container} data-testid="merchant-keyword-filter">
      <SearchBoxSchema page="store" url={isSearchClicked} />
      <a
        data-testid="toggle"
        onClick={() => {
          if (props.onClick) props.onClick();
          if (props.collapsed) {
            return inputRef.current.focus();
          }
          inputRef.current.blur();
        }}
        className={s.searchIcon}
      >
        <SearchIcon />
      </a>
      <Autocomplete
        getOptionLabel={(option: Merchant) =>
          typeof option === 'string' ? option : `${option.dbaName}`
        }
        isOptionEqualToValue={(option: Merchant, value: Merchant) => {
          return option.id === value.id;
        }}
        filterOptions={(x) => x}
        options={keywordSuggestions}
        autoComplete
        inputValue={keyword}
        includeInputInList
        clearOnBlur={false}
        selectOnFocus
        freeSolo={keyword === '' || suggestionsLoading}
        onChange={(_, newValue: Merchant) => {
          pushSearch(newValue?.dbaName ?? '');
          trackStoreLocatorSearchEvent(newValue?.dbaName);
        }}
        onInputChange={(_, newInputValue) => {
          setSearchclicked(newInputValue);
          setKeyword(newInputValue);
        }}
        popupIcon={null}
        className={s.searchInput}
        classes={{ option: s.option }}
        noOptionsText={t('page.find_stores_no_results_found')}
        renderInput={(params) => (
          <TextField
            inputRef={inputRef}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <a
                    onClick={() => {
                      pushSearch('');
                      setKeyword('');
                    }}
                  >
                    {keyword !== '' ? (
                      <CloseIcon className="text-black mr-2 cursor-pointer" />
                    ) : null}
                  </a>
                </InputAdornment>
              ),
            }}
            fullWidth
            placeholder={
              props.placeholder ?? t('page.find_stores_search_placeholder')
            }
            className={s.searchInput}
          />
        )}
      />
    </div>
  );
}
