import dynamic from 'next/dynamic';

const MerchantIndustryFilter = dynamic(
  import('../store-locator/merchant-industry-filter').then(
    (module) => module.MerchantIndustryFilter
  ),
  { ssr: false, loading: () => <div></div> }
);

export { MerchantIndustryFilter };
