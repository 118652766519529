import cn from 'classnames';
import { useState, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { MerchantIndustryFilter } from '../../lazy/merchant-industry-filter';
import { MerchantKeywordFilter } from '../merchant-keyword-filter';
import s from './merchants-results-filters.module.scss';
import { selectHasResults } from '@ee-monorepo/store-locator/shared/data-access';

export function MerchantsResultsFilters({
  className,
}: HTMLAttributes<HTMLElement>) {
  const [collapsed, setExpanded] = useState(true);
  const hasResults = useSelector(selectHasResults);

  if (!hasResults) {
    return null;
  }
  return (
    <div
      className={cn(s.container, 'flex w-full z-10', className, {
        [s.collapsed]: collapsed,
      })}
    >
      <div className={cn(s.category, 'mr-2 overflow-hidden')}>
        <MerchantIndustryFilter
          onClick={() => setExpanded(!collapsed)}
          collapsed={collapsed}
        />
      </div>
      <div className={cn(s.keyword, 'ml-2 overflow-hidden')}>
        <MerchantKeywordFilter
          onClick={() => setExpanded(!collapsed)}
          collapsed={collapsed}
        />
      </div>
    </div>
  );
}
