import { Tag } from '@ee-monorepo/shared/ui/tag';
import { Merchant } from '@ee-monorepo/shared/utilities/types';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';
import s from './merchant-product-tag.module.scss';

export interface MerchantProductTagProps {
  merchant: Merchant;
  showPrequalTag?: boolean;
}

export const MerchantProductTag = ({
  merchant,
  showPrequalTag,
}: MerchantProductTagProps) => {
  const t = useTranslations();
  const installment = t('shared.merchant_product_tag.installment');
  const leaseToOwn = t('shared.merchant_product_tag.lease_to_own');
  const loan = t('shared.merchant_product_tag.loan');
  const preQualify = t('shared.merchant_product_tag.pre_qualify');

  const getProductTag = useCallback(() => {
    if (merchant.merchantProducts?.includes('LTO')) {
      return (
        <div className="flex">
          <Tag tag={leaseToOwn} className={cn(s.leaseToOwn)} />
          {showPrequalTag && (
            <Tag tag={preQualify} className={cn(s.preQualify)} />
          )}
        </div>
      );
    } else if (merchant.merchantProducts?.includes('SLN')) {
      return (
        <Tag
          tag={merchant.portfolio ? loan : installment}
          className={merchant.portfolio ? cn(s.loan) : cn(s.installment)}
        />
      );
    } else {
      return null;
    }
  }, [merchant, leaseToOwn, showPrequalTag, preQualify, loan, installment]);

  return (
    <div
      className={cn(s.productTag, 'mb-auto', 'flex', 'mb-1')}
      data-testid="merchantProductTag"
    >
      {getProductTag()}
    </div>
  );
};
