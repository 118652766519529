import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectResultsCity,
  selectResultsState,
  selectViewMode,
  selectResultsIndustries,
  useStoreLocatorActions,
  useStoreLocatorRouterActions,
  selectMerchant,
} from '@ee-monorepo/store-locator/shared/data-access';
import { Industry } from '@ee-monorepo/shared/utilities/types';
import { buildOffsetUsingPageNumber } from '@ee-monorepo/shared/utilities/functions';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

const useStoreLocatorParams = (setSelectedLocationOnClick) => {
  const router = useRouter();
  const viewMode = useSelector(selectViewMode);
  const currentCity = useSelector(selectResultsCity);
  const currentState = useSelector(selectResultsState);
  const industries = useSelector(selectResultsIndustries);
  const merchant = useSelector(selectMerchant);
  const [cityStateHistory, setCityStateHistory] = useState({
    city: null,
    state: null,
  });
  const resetCityStateHistory = useCallback(() => {
    setCityStateHistory({ city: null, state: null });
  }, [setCityStateHistory]);
  const {
    searchMerchants,
    searchIndustries,
    clear,
    loadSingleMerchantAndResults,
  } = useStoreLocatorActions();
  const { pushCityState } = useStoreLocatorRouterActions();
  const {
    zipCode,
    zip,
    industry,
    latitude,
    longitude,
    search: keyWord,
    city,
    state,
    merchantId,
    pageNumber,
  } = router.query as {
    [key: string]: string;
  };
  const zipParam = zipCode || zip;
  const isOnlineView = router.asPath.includes('online-stores');
  const hasLocationParams = !!zipParam || (!!latitude && !!longitude);
  const hasLatLong = !!latitude && !!longitude && !zipParam;
  const hasBaseParams =
    hasLocationParams || (viewMode === 'ONLINE' && !!industry) || !!merchantId;

  useEffect(() => {
    clear();
    return () => {
      clear();
    };
  }, [clear]);

  const { setIndustry } = useStoreLocatorActions();

  useEffect(() => {
    setIndustry(industry as Industry);
  }, [setIndustry, industry]);

  /* Coordinates */
  useEffect(() => {
    if (latitude && longitude && !isOnlineView) {
      resetCityStateHistory();
      searchIndustries({
        userCoordinates: {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        },
      });
    }
  }, [
    latitude,
    longitude,
    searchIndustries,
    resetCityStateHistory,
    isOnlineView,
  ]);

  /* Zipcode */
  useEffect(() => {
    if (zipParam && !isOnlineView) {
      resetCityStateHistory();
      searchIndustries({
        zipCode: zipParam,
      });
    }
  }, [zipParam, searchIndustries, resetCityStateHistory, isOnlineView]);

  /* City & State push: subscribes to city/state changes in store and push to url */
  /* Also avoids re-adding city/state params on browser back button  */
  useEffect(() => {
    if (
      currentCity &&
      currentState &&
      !isOnlineView &&
      (city !== currentCity || state !== currentState) &&
      (cityStateHistory.city !== currentCity ||
        cityStateHistory.state !== currentState)
    ) {
      setCityStateHistory({ city: currentCity, state: currentState });
      pushCityState(currentState, currentCity, pageNumber);
    }
  }, [
    pushCityState,
    city,
    state,
    currentCity,
    currentState,
    viewMode,
    setCityStateHistory,
    cityStateHistory,
    isOnlineView,
    pageNumber,
  ]);

  /* Intial load when Industry, keyWord, pageNumber changes */
  useEffect(() => {
    if (industries?.length > 0 && !isOnlineView) {
      if (!industry && !keyWord && pageNumber === '1') {
        if (latitude && longitude) {
          searchMerchants({
            userCoordinates: {
              latitude: parseFloat(latitude),
              longitude: parseFloat(longitude),
            },
            offset: 0,
            limit: appConfig.merchantRetrievalLimit,
          });
        } else if (zipParam) {
          searchMerchants({
            zipCode: zipParam,
            offset: 0,
            limit: appConfig.merchantRetrievalLimit,
          });
        }
      }
    }
  }, [
    industries,
    keyWord,
    latitude,
    longitude,
    zipParam,
    industry,
    pageNumber,
    searchMerchants,
    isOnlineView,
  ]);

  const hasIndustries = useMemo(() => industries?.length > 0, [industries]);

  /* when Industry, keyWord, pageNumber changes after initial load */
  useEffect(() => {
    if (
      hasIndustries &&
      (industry || keyWord || parseInt(pageNumber) > 1) &&
      !isOnlineView
    ) {
      if (latitude && longitude) {
        searchMerchants({
          userCoordinates: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          },
          offset: buildOffsetUsingPageNumber(pageNumber),
          limit: appConfig.merchantRetrievalLimit,
          industry:
            industry !== 'ALL' ? (industry?.toUpperCase() as Industry) : '',
          keyWord,
        });
      } else if (zipParam) {
        searchMerchants({
          zipCode: zipParam,
          offset: buildOffsetUsingPageNumber(pageNumber),
          limit: appConfig.merchantRetrievalLimit,
          industry:
            industry !== 'ALL' ? (industry?.toUpperCase() as Industry) : '',
          keyWord,
        });
      }
    }
  }, [
    industry,
    keyWord,
    latitude,
    longitude,
    zipParam,
    pageNumber,
    hasIndustries,
    searchMerchants,
    isOnlineView,
  ]);

  /* Specific Merchant */
  useEffect(() => {
    if (merchantId && (!merchant || merchant.id !== parseInt(merchantId)))
      loadSingleMerchantAndResults(merchantId, setSelectedLocationOnClick);
  }, [
    merchantId,
    loadSingleMerchantAndResults,
    merchant,
    setSelectedLocationOnClick,
  ]);

  return { hasLocationParams, hasBaseParams, hasLatLong };
};

export { useStoreLocatorParams };
