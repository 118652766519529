import dynamic from 'next/dynamic';

const IconIndustry = dynamic(
  import('@ee-monorepo/shared/ui/icons').then((module) => module.IconIndustry),
  {
    ssr: false,
    loading: () => <div></div>,
  }
);

export { IconIndustry };
