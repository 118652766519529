import dynamic from 'next/dynamic';

const MerchantDetailContainer = dynamic(
  import('../store-locator/merchant-detail').then(
    (module) => module.MerchantDetailContainer
  ),
  {
    ssr: false,
    loading: () => <div></div>,
  }
);

export { MerchantDetailContainer };
