import { Avatar } from '@ee-monorepo/shared/ui/avatar';
import { Card } from '@ee-monorepo/shared/ui/card';
import { Tag } from '@ee-monorepo/shared/ui/tag';
import {
  removeHttp,
  formatAddress,
} from '@ee-monorepo/shared/utilities/functions';
import { ReactNode, createContext, useContext, useState } from 'react';
import { Merchant } from '@ee-monorepo/shared/utilities/types';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { MerchantProductTag } from '../merchant-product-tag';
import s from './merchant-card.module.scss';

export interface MerchantCardProps {
  merchant: Merchant;
  active?: boolean;
  showWebSite?: boolean;
  showPrequalTag?: boolean;
  onClick?: (merchant: Merchant) => void;
  onMouseOver?: (merchant: Merchant) => void;
  merchantCardViewCheck?: boolean;
}

export const MerchantCard = ({
  merchant,
  active,
  showWebSite,
  showPrequalTag,
  onMouseOver,
  onClick,
  merchantCardViewCheck,
}: MerchantCardProps) => {
  const { onMerchantCardHovered, hoveredMerchantId } =
    useMerchantCardHoverContext();
  const t = useTranslations();

  return (
    <Card
      data-testid="merchant-item"
      onClick={(_) => onClick?.(merchant)}
      onMouseOver={() => {
        onMerchantCardHovered(merchant.id);
        if (onMouseOver) onMouseOver(merchant);
      }}
      active={
        hoveredMerchantId && hoveredMerchantId !== merchant.id ? false : active
      }
      className={cn(
        s.merchantCardRoot,
        !merchantCardViewCheck ? 'h-full' : 'mb-4',
        'border-SnapGrey400'
      )}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row grow">
          <div className="logoWrapper">
            <Avatar displayText={merchant?.dbaName} url={merchant?.logoURL} />
          </div>
          <div className="ml-2">
            <h5
              className={cn(
                s.title,
                'bodyHeavyFont',
                'mb-0 line-clamp-2',
                'capitalize'
              )}
              data-testid="merchantDbaName"
            >
              {merchant.dbaName.toLowerCase()}
            </h5>
            <div
              className={cn(s.subTitle, 'secondaryFont line-clamp-2')}
              data-testid="merchantSiteOrAddress"
            >
              {showWebSite
                ? removeHttp(merchant.website)
                : formatAddress(merchant.formattedAddress)}
            </div>
          </div>
        </div>

        <div
          className={cn(
            s.merchantCardFooter,
            'flex',
            'items-baseline',
            'justify-between',
            'grow-0',
            'mt-3'
          )}
        >
          <div
            className={cn(s.industries, 'flex', 'items-baseline', 'flex-wrap')}
          >
            {!!merchant.industries &&
              merchant.industries.map((industry) => (
                <Tag
                  key={industry}
                  tag={t(`shared.industries.${industry}.label`) as string}
                  className="mb-1"
                />
              ))}
            <MerchantProductTag
              merchant={merchant}
              showPrequalTag={showPrequalTag}
            />
          </div>
          {!isNaN(merchant?.distance) && (
            <span
              className={cn(
                s.distance,
                'inline-block',
                'tagSmallFont',
                'text-end',
                'uppercase'
              )}
            >
              {merchant.distance} mi
            </span>
          )}
        </div>
      </div>
    </Card>
  );
};

/** shares state of last merchant hovered */
interface MerchantCardHoverContextProps {
  hoveredMerchantId?: number;
  onMerchantCardHovered?: (merchantId: number) => void;
}

export const MerchantCardHoverContext =
  createContext<MerchantCardHoverContextProps>({
    onMerchantCardHovered: () => {},
  });

/** shares and sets state of last merchant hovered */
export function MerchantCardHoverProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [hoveredMerchantId, onMerchantCardHovered] = useState<number>(null);
  return (
    <MerchantCardHoverContext.Provider
      value={{
        hoveredMerchantId,
        onMerchantCardHovered,
      }}
    >
      {children}
    </MerchantCardHoverContext.Provider>
  );
}

export function useMerchantCardHoverContext() {
  return useContext(MerchantCardHoverContext);
}
